import React, { useState, useEffect } from "react";
import leadIdContext from "../LeadIdContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
//Import CSS
import "../../styles/dashboardCss/leads.css";
//Import Components
import FilterSidebar from "../../components/LeadsLeftSection";
import LeadsRightSection from "../../components/LeadsRightSection";

const initialFilterState = {
  cityName: "",
  companyName: "",
  date: "",
  leadOwnerName: "",
  verified: "",
  unverified: "",
  rejected: "",
  dateRange: "",
  customStartDate: "",
  customEndDate: "",
};

// Function to load filter state from localStorage
const loadFilterState = () => {
  const savedFilter = localStorage.getItem("filterData");
  return savedFilter ? JSON.parse(savedFilter) : initialFilterState;
};

const Leads = () => {
// Function to load filter state from localStorage
// const [filterData, setFilterData] = useState(loadFilterState);
const [filterData, setFilterData] = useState({
  cityName: "",
  companyName: "",
  date: "",
  leadOwnerName: "",
  verified: "",
  unverified: "",
  rejected: "",
  dateRange: "",
  customStartDate: "",
  customEndDate: "",
});


  const location = useLocation();
   const filterType = location.state?.lead;

  useEffect(()=>{
   
    if(filterType){ 
      setFilterData({
        cityName: "",
        companyName: "",
        date: "",
        leadOwnerName: "",
        verified: true,
        unverified:'',
        rejected: "",
        dateRange: "",
        customStartDate: "",
        customEndDate: "",
      })
     }
  },[]) 

 
 
const navigate = useNavigate();
  // Save filter state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("filterData", JSON.stringify(filterData));
  }, [filterData]);

  const [leadCostumerId, setLeadCostumerId] = useState([]);

  return (
    <div className="container-fluid dashboard_leads_main_container">
              
      <div className="row dashboard_filter_sidebar_row">
        {/* In Leads Page Left Filter Side Bar Section */}
        <div className=" col-xl-2 col-md-3 filter_sidebar_col">
       
          <div className="filter_sidebar_mainDiv" >
          <FilterSidebar filterData={filterData} setFilterData={setFilterData} />
          </div>
        </div>
        {/* <div className="filter_div">
        <FilterSidebar setFilterData={setFilterData} />
        </div> */}
         {/* <FilterSidebar setFilterData={setFilterData} /> */}
         
        {/* Right Section Who's Section Have Tables Form Data */}
        <div className="col-xl-10 col-md-9" >
       
          <leadIdContext.Provider value={{ leadCostumerId, setLeadCostumerId }}>
            <LeadsRightSection
              leadCostumerId={leadCostumerId}
              setLeadCostumerId={setLeadCostumerId}
              filterData={filterData}
             
            />
          </leadIdContext.Provider>
        </div>
      </div>
    {/* </div> */}
    </div>
  );
};

export default Leads;
