import { useEffect, useState, useRef } from "react";



const Mewpin=({handleDelete}) => { 
    
  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

  const [pin, setPin] = useState({
    dig1: null,
    dig2: null,
    dig3: null,
    dig4: null,
  });
  const [focus, setFocus] = useState("dig1");

  // Create refs for each input field
  const dig1Ref = useRef(null);
  const dig2Ref = useRef(null);
  const dig3Ref = useRef(null);
  const dig4Ref = useRef(null);

  useEffect(() => {
    // Focus the input element based on the focus state
    if (focus === "dig1") dig1Ref.current?.focus();
    if (focus === "dig2") dig2Ref.current?.focus();
    if (focus === "dig3") dig3Ref.current?.focus();
    if (focus === "dig4") dig4Ref.current?.focus();
  }, [focus]);

  const handleMpin = ({ target: { name, value, id } }) => {
    if(value){
    setPin((prev) => ({ ...prev, [name]: value }));

    let n=Number(name[3])
    if (n < 4) {
      n += 1;
    } else {
      n = (n + 1) % 4;
    }

   setFocus(`dig${n}`);
  }
  };

   const handleform = async (e)=>{ let data=pin.dig1+pin.dig2+pin.dig3+pin.dig4;
      e.preventDefault();
     await handleDelete(data,tokenId);
   }

  return (
    <div  style={{width:"100%",padding:'20px'}}>
      <form onSubmit={handleform} style={{display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}>
        <div style={{width:"80%",display:'flex',justifyContent:'space-around'}}>
        <input
          ref={dig1Ref}
          type="text"
          name="dig1"
          maxLength={1}
          style={{
            width: "50px",
            height: "40px",
            padding: "8px",
            textAlign: "center",
            marginRight: "15px",
            outline: "none",
          }}
          onInput={handleMpin}
        />
        <input
          ref={dig2Ref}
          type="text"
          name="dig2"
          maxLength={1}
          style={{
            width: "50px",
            height: "40px",
            padding: "8px",
            textAlign: "center",
            marginRight: "15px",
            outline: "none",
          }}
          onInput={handleMpin}
        />
        <input
          ref={dig3Ref}
          type="text"
          name="dig3"
          maxLength={1}
          style={{
            width: "50px",
            height: "40px",
            padding: "8px",
            textAlign: "center",
            marginRight: "15px",
            outline: "none",
          }}
          onInput={handleMpin}
        />
        <input
          ref={dig4Ref}
          type="text"
          name="dig4"
          maxLength={1}
          style={{
            width: "50px",
            height: "40px",
            padding: "8px",
            textAlign: "center",
            marginRight: "15px",
            outline: "none",
          }}
          onInput={handleMpin}
        />
        </div>
        <div style={{width:"100%" ,display:"flex",alignItems:"center",justifyContent:'space-between',marginTop:"30px"}}><span style={{}}>Enter your MPIN</span>
          <button  type="submit" style={{border:"2px solid grey",borderRadius:"5px",color:'#696b6a',padding:"4px 7px"}}>DELETE</button>
        </div>
      </form>
    </div>
  );
};

export default Mewpin;
