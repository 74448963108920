import React from "react";

// React Icons
import { SiGoogleadmob } from "react-icons/si";
import { MdTask } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaHandshakeSimple } from "react-icons/fa6";

// Import Shared Component
import DashboardSection1Table from "./shared/DashboardSection1Table";
import { Link } from "react-router-dom";

// import DashboardSection2Table from "./shared/DashboardSection2Table";
const DashboardSection1 = ({ getCurrentUserData }) => {
  return (
    
    <div className="super_admin_first_section_cards_div row row-cols-xl-3 ">
    {/* first Card */}
    <div className="super_admin_first_section_single_card col  super_admin_first_section_first_card">
      <div className="super_admin_first_section_card_icon_div">
        {
         <FaHandshakeSimple className="dashboard_card_icon dashboard_firstCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link to='/deals' className="super_admin_first_section_card_link">
          My Open Deals
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalDeal}
        </p>
      </div>
    </div>
    {/* Second Card */}
   
    <div className="super_admin_first_section_single_card col  super_admin_first_section_second_card">
      <div className="super_admin_first_section_card_icon_div">
        {
        <SiGoogleadmob className="dashboard_card_icon dashboard_secondCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/leads" state={{lead:"Varified"}}>
          Varified Leads
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalLead}
        </p>
      </div>
    </div>

    {/* Third Card */}
    <div className="super_admin_first_section_single_card col  super_admin_first_section_third_card">
      <div className="super_admin_first_section_card_icon_div">
        {
          <MdTask className="dashboard_card_icon dashboard_thirdCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/tasks">
          My Task
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalTask}
        </p>
      </div>
    </div>
    {/* Fourth Card */}
    <div className="super_admin_first_section_single_card col  super_admin_first_section_fourth_card">
      <div className="super_admin_first_section_card_icon_div">
        {
           <BiSolidPhoneCall className="dashboard_card_icon dashboard_fourthCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/schedule-call">
          My Calls Today
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalCall}
       
        </p>
      </div>
    </div>
  </div>

    
    // <div >
    //   {/* Cards */}
    //   {/* <div className="col-xl-4 col-md-4"> */}
    //   <div style={{display:"flex",alignItems:"center",justifyContent:'space-around',flexWrap:"wrap"}}>
    //     {/* <div className="row row-cols-3 dashboard_card_mainDiv" > */}
    //     <div style={{display:"flex",alignItems:"center",justifyContent:'space-between',flexWrap:"wrap"}} >

    //       {/* First Card */}
    //       <div className="super_admin_first_section_single_card col  super_admin_first_section_first_card">
    //       <div className="super_admin_first_section_card_icon_div">
    //         {
    //            <FaHandshakeSimple className="dashboard_card_icon dashboard_firstCard_icon" />
    //         }
    //       </div>
    //       <div className="super_admin_first_section_text_div">
    //         <p className="super_admin_first_section_card_text">
    //           <Link className="super_admin_first_section_card_link">
    //           My Open Deals
    //           </Link>
    //         </p>
    //         <p className="super_admin_first_section_card_number">
    //         {getCurrentUserData?.totalDeal}
    //         </p>
    //       </div>
    //     </div>
         
    //       {/* ----------------------------------------------- */}
    //       <div className="super_admin_first_section_single_card col  super_admin_first_section_first_card">
    //         <div className="dashboard_mainDiv_single_card">
    //           <div className="dashboard_card_icon_div">
    //             {
    //               <FaHandshakeSimple className="dashboard_card_icon dashboard_firstCard_icon" />
    //             }
    //           </div>
    //           <div>
    //           <p className="dashboard_card_text">
    //             <Link className="dashboard_card_text_link" to="/deals">
    //               My Open Deals
    //             </Link>
    //           </p>
    //           <p className="dashboard_card_number">
    //             {getCurrentUserData?.totalDeal}
    //           </p>
    //           </div>
             
    //         </div>
    //       </div>
    //       {/* Second Card */}
    //       <div className="card col dashboard_mainDiv_card mb-3  dashboard_mainDiv_secondCard">
    //         <div className="dashboard_mainDiv_single_card">
    //           <div className="dashboard_card_icon_div">
    //             {
    //               <SiGoogleadmob className="dashboard_card_icon dashboard_secondCard_icon" />
    //             }
    //           </div>

    //           <p className="dashboard_card_text">
    //             <Link className="dashboard_card_text_link" to="/leads">
    //               Varified Leads
    //             </Link>
    //           </p>
    //           <p className="dashboard_card_number">
    //             {getCurrentUserData?.totalLead}
    //           </p>
    //         </div>
    //       </div>
    //       {/* Third Card */}
    //       <div className="card col dashboard_mainDiv_card mb-3 dashboard_mainDiv_thirdCard">
    //         <div className="dashboard_mainDiv_single_card">
    //           <div className="dashboard_card_icon_div">
    //             {
    //               <MdTask className="dashboard_card_icon dashboard_thirdCard_icon" />
    //             }
    //           </div>
    //           <p className="dashboard_card_text">
    //             <Link className="dashboard_card_text_link" to="/tasks">
    //               My Task
    //             </Link>
    //           </p>
    //           <p className="dashboard_card_number">
    //             {getCurrentUserData?.totalTask}
    //           </p>
    //         </div>
    //       </div>
    //       {/* Fourth Card */}
    //       <div className="card col dashboard_mainDiv_card mb-3  dashboard_mainDiv_fourthCard">
    //         <div className="dashboard_mainDiv_single_card">
    //           <div className="dashboard_card_icon_div">
    //             {
    //               <BiSolidPhoneCall className="dashboard_card_icon dashboard_fourthCard_icon" />
    //             }
    //           </div>
    //           <p className="dashboard_card_text">
    //             <Link className="dashboard_card_text_link" to="/call-schedule">
    //               My Calls Today
    //             </Link>
    //           </p>
    //           <p className="dashboard_card_number">
    //             {getCurrentUserData?.totalCall}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* Tables */}
    //   <div className="col-xl-8 col-md-8">
    //     <DashboardSection1Table />
    //   </div>
    // </div>
  );
};

export default DashboardSection1;
