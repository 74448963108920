import React, { useState, useCallback, useEffect } from "react";

//Import CSS
import "../../styles/dashboardCss/dashboard.css";
// Import Component
import DashboardSection1 from "../../components/DashboardSection1";
import DashboardSection2 from "../../components/DashboardSection2";
import DashboardSection3 from "../../components/DashboardSection3";
// Import api function from controller
import { getCurrentUser } from "../../controller/fetchApi";
import SuperAdminSection1 from "../../components/SuperAdminSection1";
import {
  getTotalLeadsInSuperAdmin,
  getTotalRoles,
} from "../../controller/fetchApi";
import { useLocation } from "react-router-dom";
import Setpin from "../../components/setMpin";
import { setmpin } from "../../controller/fetchApi";
import Toast from "../../components/Toast";

const Dashboard = () => {
  const location = useLocation();
  const userId = location.state?.userId;
  const pin = location.state?.pin;


  const [showToast, setShowToast] = useState({ success: false, message: "",optmessage:'' });

  const [getCurrentUserData, setCurrentUserData] = useState();
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  //set MPIN popup
  const [MPIN, setMPIN] = useState(false);

 useEffect(() => {
  if(pin)
    setMPIN(pin);  
 }, [])
 
  

  //setting MPIN
  const setpin = async (mPin) => {
    const setsuccessfully = await setmpin(tokenId, mPin);
    if (setsuccessfully?.status === 200) {
      //close pin popup
      setMPIN(false)
      setShowToast({ success: true, message: "MPIN set successfully.",optmessage:'setting MPIN' })
    }
  };
  //  Get Current User Data OR Api
  const getUser = useCallback(async () => {
    try { let res;
     // const res = await getCurrentUser(tokenId, userId);
      if(userId){
         res = await getCurrentUser(tokenId,userId);

      }else{
         res = await getCurrentUser(tokenId);
      }

      if (res) {
        setCurrentUserData(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [tokenId,userId]);

 useEffect(() => { 
    getUser();
}, [getUser])

  
  //------------------------------------
  // const [tokenId ,settokenId] = useState(null);

  // useEffect(()=>{
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   settokenId(user?.data?.token)
  // },[])

  const [loading, setloading] = useState(true);
  // Get Total Leads
  const [totalLeads, setTotalLeads] = useState();
  const getTotalLeads = async () => {
    try {
      const response = await getTotalLeadsInSuperAdmin(tokenId);
      setTotalLeads(response);
      setloading(false);
    } catch (error) {
      console.log("Not Get Total Leads super Admin", error);
    }
  };

  // Get All Roles
  const [allRoles, setAllRoles] = useState([]);
  const getAllRoles = async () => {
    try {
      const result = await getTotalRoles(tokenId);
      setAllRoles(result);
    } catch (error) {
      console.log("Not Get All Roles", error);
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      await getTotalLeads();
      await getAllRoles();
    };
    if (tokenId) {
      fetchdata();
    }
  }, [tokenId]);

  return (
    <div className="dashboard_main_container container-fluid">
      {userId && (
        <div className="dashboard_username_div">
          <p className="dashboard_user_name">{`Welcome ${getCurrentUserData?.fullName}`}</p>
          <p className="dashboard_user_name2">{`username ${getCurrentUserData?.userName}`}</p>
        </div>
      )}
      {!userId && (
        <SuperAdminSection1
          totalLeads={totalLeads}
          allRoles={allRoles}
          userrole={userrole}
          getCurrentUserData={getCurrentUserData}
        />
      )}
      {/* {userId&&( <div className="super_admin_first_section_left_div">
          <p className="super_admin_first_section_user_name">{`Welcome ${getCurrentUserData?.fullName}`}</p>
          <p className="super_admin_first_section_user_name2">{`username ${getCurrentUserData?.email}`}</p>
        </div>)} */}
      <div className="dashboard_main_div">
        {/* First Section In Cards & My Meetings This Month */}
        <DashboardSection1 getCurrentUserData={getCurrentUserData} />
         {/* My Deals Closing This Month */}
         <DashboardSection3 />
        {/* My Open Task This Month & Piplibe Stage Chart */}
        <DashboardSection2 />
       
      </div>

      {MPIN && (
        <div
          className="set_pin"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Setpin setpin={setpin} />
        </div>
      )}

<Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default Dashboard;
