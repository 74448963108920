import React, { useState } from "react";
// import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
// css
import "../styles/signup.page.css";
import "../styles/login.page.css";

// React Icons
import { FcFeedback } from "react-icons/fc";
import { IoArrowBackCircleOutline } from "react-icons/io5";

// Formik
import { replace, useFormik } from "formik";
import { loginFormSchema } from "../schema/FormValidation";

// Imags
// import orLogin from "../images/orLogin.jpg";
import loginImg from "../images/login-image.png";

// Api Call & Function
// import { loginUser, loginUserThroughGoogle } from "../controller/fetchApi";
import { loginUser } from "../controller/fetchApi";
import Toast from "../components/Toast";



// import { useAuth0 } from "@auth0/auth0-react";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();


  // Toast
  const [showToast, setShowToast] = useState({ success: false, message: "" });
  

  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" });
    }, 3000);
  };
  if (showToast.message) { 
    hideToast();
  }

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: loginFormSchema,
    onSubmit: async (values, { resetForm,setSubmitting }) => {
      const loginSuccessFully = await loginUser(values, setShowToast);

       const userRole = loginSuccessFully?.data?.data?.roleAndPermissions?.roles[0]?.role;
      if(userRole==='SUPERADMIN'){
        navigate("/companylist" ,{replace:true})
      }else
      if ((loginSuccessFully?.status === 200)&&(loginSuccessFully?.data?.data?.pinStatus===true)){ 
             navigate("/dashboard", { replace: true });
             }
             else  if ((loginSuccessFully?.status === 200)&&(loginSuccessFully?.data?.data?.pinStatus===false)){ 
            navigate('/dashboard', { replace: true, state:{pin:true}});
             } 
     
       setSubmitting(false);
       resetForm();
    },
  });

  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

 

  return (
    <div className="container-fluid signup_body_div">
      {/* <Deleteuserconfirmation /> */}
      <div className="row">
        {/* left Main Div */}
        <div className="col-xl-6 col-md-6 col-sm-12 signup_right_bodyDiv">
          <div className="signup_right_mainDiv">
            <img src={loginImg} alt="signin_random_img" className="img-fluid" />
          </div>
        </div>
        {/* left Main Div End*/}

        {/* right Main Div Start*/}
        <div className="col-xl-6 col-md-6 col-sm-12">
          <div className="container signup_left_container login_left_container">
            <div id="signup_left_mainDiv" className="login_left_mainDiv">
              <form onSubmit={handleSubmit} style={{width:"80%"}}>
                <Link to="/" title="Go to landing page">
                  <IoArrowBackCircleOutline className="login_back_button" />
                </Link>
                <p className=" signup_text_in_signup_left_mainDiv">Login</p>
                <div className="formGroup">
                  {/* Email */}
                  <div className="signup_input_div loginPageMainDiv">
                    <div className="mb-3 position-relative ">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label signup_div_input"
                      >
                        Email address. <span className="required_sign"></span>
                      </label>
                      <input
                        type="email"
                        className={`form-control signup_email_form_control login_page_input ${
                          errors.email && touched.email
                            ? "signup_input_form"
                            : ""
                        }`}
                        id="exampleFormControlInput1"
                        name="email"
                        placeholder="John@example.com"
                        value={values.email}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {touched.email && errors.email && (
                        <small className="loginPageErrorMessage">
                          {errors.email}
                        </small>
                      )}
                      <FcFeedback className="signup_input_icons" />
                    </div>
                  </div>
                  {/*Password */}
                  <div className="signup_input_div loginPageMainDiv">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label signup_div_input"
                      >
                        Password <span className="required_sign"></span>
                      </label>
                      <input
                        // type="password
                        type={showPassword ? "password" : "text"}
                        className={`form-control signup_email_form_control login_page_input ${
                          errors.password && touched.password
                            ? "signup_input_form"
                            : ""
                        }`}
                        id="exampleFormControlInput2"
                        placeholder='************'
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {/* Error Message */}
                      {touched.password && errors.password && (
                        <small className="loginPageErrorMessage">
                          {errors.password}
                        </small>
                      )}
                      {/* Show Unshow functionlity */}
                      {showPassword ? (
                        <p
                          className="signup_input_icons"
                          onClick={handleClickShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          🙈
                        </p>
                      ) : (
                        <p
                          className="signup_input_icons"
                          onClick={handleClickShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          🐵
                        </p>
                      )}
                    </div>
                    <div>
                      <Link
                        className="login_forgot_password_link"
                        to="/forgotpassword"
                      >
                        <p className="login_forgot_password">
                          Forgot Password?
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Submit Button */}
                <div className="signup_submit_div">
                  <button type="submit" className=" signup_submit_button" disabled={isSubmitting}>
                    Login
                  </button>
                </div>
                {/* OR LOGIN */}
              </form>  
            </div>
          </div>
        </div>
        {/* right Main End */}
      </div>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default Login;
