import React, { useState, useEffect, useCallback, useContext } from "react";
// React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsTrash } from "react-icons/bs";
import { TbFileDownload } from "react-icons/tb";
import ContactRightSectionTable from "../../components/ContactRightSectionTable";
// Import Toast
import Toast from "../../components/Toast";
// Context
import permissionContext from "../PermissionsContext";
// Controller
import {
  getAllContact,
  deleteContact,
  downloadContacts,
} from "../../controller/fetchApi";
// React Router Dom
import { Link, useNavigate } from "react-router-dom";

import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../../components/goback";


const Contact = () => {
  const navigate = useNavigate()
  //  Get Permission from app.js
  const { contactsPermission } = useContext(permissionContext);
  // Start Toast -------
  const [showToast, setShowToast] = useState({ success: false, message: "",optmessage:'' });

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [contactCostumerId, setContactCostumerId] = useState([]);
  const [getAllContactData, setAllContactData] = useState([]);

  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  //set loader
 const [loading,setloading] = useState(true) 

  //  Get All Contact Data
  const getContactsData = useCallback(async () => {
    try {
      await getAllContact(pageNo, tokenId).then((res) => { 
        console.log(res)
        setAllContactData(res);
      });
    } catch (error) {
      console.log(error);
    }finally{
      setloading(false)
    }
  }, [pageNo, tokenId]);
  //user id for deletion and confirmation for deletion
 const [dellead,setdellead]=useState({
  leadId:null,setLeadId:null
 });
 const [show,setshow] = useState(false)

  // Delete Contact Api Start---------------
  const handleDeleteContact = async () => {
    if(contactCostumerId.length){
      try {
        await deleteContact(contactCostumerId, setShowToast, tokenId);
        if (deleteContact) {
          getContactsData();
        }
      } catch (error) {
        console.log("Did Not Delete Found Error", error);
      }finally{
        setContactCostumerId([])
        setshow(false)
      }
    }else{
      setShowToast({ success: true, message: "Select contact to delete.",optmessage:'Deleting contact.' })
    }
    
  };

  // Handle Download Contact
  const handleDowloadContacts = async () => {
    try {
      await downloadContacts(uid, setShowToast, tokenId);
    } catch (error) {
      console.log("Contact Downloaded:", error);
    }
  };
  // Pagination Function Code------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllContactData?.totalPages || 1;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };

  useEffect(() => {
    getContactsData();
  }, [getContactsData]);        


 
  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
         {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
    <div className="dashboard_content_wrapper">
    
      {/* Btn div */}
      <div className="dashboard_leads_btn_mainDiv">
      {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
                <Goback />
        <div className="dashboard_leads_btns_div">
        
          {(contactsPermission?.includes("Delete") ||
          contactsPermission?.includes("Download"))&&getAllContactData?.content?.length>0 ? (
            <div className="dashboard_leads_action_btn_div">
              <button
                // id="this_dropdown"
                className="dashboard_section1_table_edit_button dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-target="#action_menu"
                 aria-controls="#action_menu"
                
              >
                Actions
              </button>
              <ul   
                className="dropdown-menu"
                id="action_menu"
                aria-labelledby="editDeleteDropdown"
              >
                {/* Delete Btn */}
                {contactsPermission?.includes("Delete") ? (
                  <li style={{cursor:"pointer"}}>
                    <span
                      className="dropdown-item"
                      onClick={() => { console.log(contactCostumerId?.length)
                       // handleDeleteContact(contactCostumerId,setContactCostumerId)

                        if( contactCostumerId?.length){
                          setshow(true)
                          setdellead({
                            leadId:contactCostumerId,setLeadId:setContactCostumerId
                           })
                        }else{
                          setShowToast({ success: true, message: "Select Contact to delete." ,optmessage:"Deleting contact."})
                        }  
                      }}
                    >
                      <BsTrash className="dashboard_section1_table_deleteBtn" />
                      Delete
                    </span>
                  </li>
                ) : (
                  ""
                )}
  
                {/* Downloads Btn */}
                {contactsPermission?.includes("Download") ? (
                  <li style={{cursor:"pointer"}}>
                    <span
                      className="dropdown-item"
                      onClick={() => handleDowloadContacts()}
                    >
                      <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                      Download Contacts
                    </span>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          ) : (
            ""
          )}
  
          {/* Create Contact Btn */}
          {contactsPermission?.includes("Create") ? (
            <div
              // style={{ display: "flex", alignItems: "center" }}
              className="dashboard_leads_create_btn_div"
            >
              <button className="btn-shiny2">
                <Link
                  className="dashboard_leads_create_link"
                  to="/create-contact"
                >
                  <span>
                    <MdAdd />
                  </span>
                  Create Contact
                </Link>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
  
      {/* Table Div */}
      <div className="dashboard_leads_table_div">
      {loading?<Loader />:
        <ContactRightSectionTable
          tblHead={{
            firstHead: "Company Name",
            secondHead: "Email",
            thirdHead: "Contact",
            fourthHead: "Address",
            fifthHead: "View",
            sixthHead: "Account Action",
            seventhHead: "Lead Id",
          }}
          redirectLink="/contact-details"
          getAllContactData={getAllContactData}
          tableName="contactTable"
          contactCostumerId={contactCostumerId}
          setContactCostumerId={setContactCostumerId}
        />
}
      </div>
  
      {/* Pagination Div */}
      <div className="dashboard_leads_pagination_div">
        <nav aria-label="...">
          <ul className="pagination">
            {/* Previous page button */}
            <li className="page-item dashboard_leads_pagination_pageItem">
              <a
                className="page-link"
                href="#!"
                onClick={handlePreviousPageClick}
              >
                <IoIosArrowBack />
              </a>
            </li>
  
            {/* Render page numbers */}
            {Array.from({ length: pagesToShow }, (_, index) => {
              const pageIndex = pageRangeStart + index;
              return (
                pageIndex < totalPages && (
                  <li
                    key={pageIndex}
                    className={`page-item ${
                      pageIndex === pageNo ? "active" : ""
                    } dashboard_leads_pagination_pageItem`}
                  >
                    <a
                      className="page-link"
                      href="#!"
                      onClick={() => handlePageClick(pageIndex)}
                    >
                      {pageIndex + 1 < 10
                        ? `0${pageIndex + 1}`
                        : pageIndex + 1}
                    </a>
                  </li>
                )
              );
            })}
  
            {/* Next page button */}
            <li className="page-item dashboard_leads_pagination_pageItem">
              <a
                className="page-link"
                href="#!"
                onClick={handleNextPageClick}
              >
                <IoIosArrowForward className="btn_IoIosArrowForward" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
  
      <Deleteuserconfirmation
        show={show}
        setShow={setshow}
        handleDelete={handleDeleteContact}
        sourceName="Contact"
      />
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  </div>
  
  
  );
};

export default Contact;
