import { useCallback, useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Goback from "./goback";
import Loader from "../pages/Loader";
import {
  getAllCompanies,
  getAllUsersMadeByAdmin,
} from "../controller/fetchApi";
import CreatedUserTable from "./CreatedUserTable";
import CreatedCompanyTable from "./createCompanyTable";

const CompanyList = () => {
  const navigate = useNavigate();

  // Start Toast -------
  const [showToast, setShowToast] = useState({ success: false, message: "" });
  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  if (showToast) {
    hideToast();
  }
  //End Toast-----------
  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);
  // Pagination Function ------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = 1;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };
  //reset user data
  const [currdata, setcurrdata] = useState(false);
  //  Get All Users Api
  const [getAllCompanyData, setAllCompany] = useState(null);

  //set loader
  const [loading, setloading] = useState(true);

  const getAllCompany = useCallback(async () => {
    try {
      const result = await getAllCompanies(tokenId, pageSize, pageNo);

      setAllCompany(result);
    } catch (error) {
    } finally {
      setloading(false);
    }
  }, [tokenId, pageSize, pageNo]);

  useEffect(() => {
    getAllCompany();
  }, [getAllCompany, currdata]);

  const handlePagesize = (event) => {
    setPagesize(event.target.value);
  };

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard_content_wrapper" style={{ clear: "both" }}>
          <div style={{ width: "100%", padding: "10px 20px 20px 20px" }}>
            <Goback />
            {["SUPERADMIN"].includes(userrole) && (
              <div
                className="dashboard_leads_create_btn_div super_admin_first_section_right_div"
                style={{ float: "right", clear: "both" }}
              >
                <button className="btn-shiny2">
                  <Link
                    className="dashboard_leads_create_link"
                    to="/create-company"
                  >
                    <span>
                      <MdAdd />
                    </span>
                    Create
                  </Link>
                </button>
              </div>
              // </div>
            )}
          </div>
          {/* Table Div */}
          <div className="dashboard_leads_table_div">
            <CreatedCompanyTable
              setcurrdata={setcurrdata}
              tblHead={{
                firstHead: "Sr. No",
                secondHead: "Company Name",
                thirdHead: "Company Email",
                fourthHead: "Contact Number",
                fifthHead: "Address",
                sixthHead: "Action",
              }}
              data={getAllCompanyData?.content}
              getAllCompany={getAllCompany}
              redirectLink="/role&permission"
            />
          </div>
         
          {/* Pagination Div */}
          <div className="dashboard_leads_pagination_div">
            <nav aria-label="...">
              <ul
                className="pagination"
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* Previous page button */}
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handlePreviousPageClick}
                  >
                    <IoIosArrowBack />
                  </a>
                </li>

                {/* Render page numbers */}
                {Array.from({ length: pagesToShow }, (_, index) => {
                  const pageIndex = pageRangeStart + index;
                  return (
                    pageIndex < totalPages && (
                      <li
                        key={pageIndex}
                        className={`page-item ${
                          pageIndex === pageNo ? "active" : ""
                        } dashboard_leads_pagination_pageItem`}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => handlePageClick(pageIndex)}
                        >
                          {pageIndex + 1 < 10
                            ? `0${pageIndex + 1}`
                            : pageIndex + 1}
                        </a>
                      </li>
                    )
                  );
                })}

                {/* Next page button */}
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handleNextPageClick}
                  >
                    <IoIosArrowForward className="btn_IoIosArrowForward" />
                  </a>
                </li>
                <input
                  className="company_page_size"
                  type="number"
                  defaultValue={12}
                  min={1}
                  step={4}
                  onChange={handlePagesize}
                />
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyList;
