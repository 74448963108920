import React, { useState, useEffect, useCallback } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CreatedUserTable from "../components/CreatedUserTable";
// Controller Method
import { getAllUsersMadeByAdmin } from "../controller/fetchApi";
import Loader from "./Loader";
import { Link, useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import Signup from "./Signup";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../components/goback";

const CreatedUser = () => {
  const navigate = useNavigate();
  // Start Toast -------
  const [showToast, setShowToast] = useState({ success: false, message: "" });
  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  if (showToast) {
    hideToast();
  }
  //End Toast-----------
  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);

  // Pagination Function ------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = 1;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };
  //reset user data
  const [currdata, setcurrdata] = useState(false);
  //  Get All Users Api
  const [getAllUsers, setAlluser] = useState(null);

  //set loader
  const [loading, setloading] = useState(true);

  const getAllUser = useCallback(async () => {
    try {
      const result = await getAllUsersMadeByAdmin(tokenId);
      setAlluser(result);
    } catch (error) {
    } finally {
      setloading(false);
    }
  }, [tokenId, setAlluser]);

  useEffect(() => {
    getAllUser();
  }, [getAllUser, currdata]);

  const [createUser, setcreateUser] = useState(false);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      {/* <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link> */}
      {/* ------------------------------------------- */}
      {/* <div style={{ width: "100%", padding: "10px 20px 20px 20px" }}>
      <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link>
      {["SUPERADMIN", "ADMIN"].includes(userrole) && (
        // <div style={{ width: "100%", padding: "20px 20px 40px 20px" }}>
          <div
            className="dashboard_leads_create_btn_div super_admin_first_section_right_div"
            style={{ float: "right", clear: "both" }}
          >
            <button
              className="btn-shiny2"
              // data-bs-toggle="modal"
              // data-bs-target="#updateLeadModal"
              onClick={() => setcreateUser(true)}
            >
              <Link className="dashboard_leads_create_link" to="#">
                <span>
                  <MdAdd />
                </span>
                Create User
              </Link>
            </button>
          </div>
        // </div>
      )
      }
      </div> */}
      {/* ------------------------------------------- */}
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard_content_wrapper" style={{ clear: "both" }}>
             <div style={{ width: "100%", padding: "10px 20px 20px 20px" }}>
      {/* <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link> */}
      <Goback />
      {["SUPERADMIN", "ADMIN"].includes(userrole) && (
        // <div style={{ width: "100%", padding: "20px 20px 40px 20px" }}>
          <div
            className="dashboard_leads_create_btn_div super_admin_first_section_right_div"
            style={{ float: "right", clear: "both" }}
          >
            <button
              className="btn-shiny2"
              // data-bs-toggle="modal"
              // data-bs-target="#updateLeadModal"
              onClick={() => setcreateUser(true)}
            >
              <Link className="dashboard_leads_create_link" to="#">
                <span>
                  <MdAdd />
                </span>
                Create User
              </Link>
            </button>
          </div>
        // </div>
      )
      }
      </div>
          {/* Table Div */}
          <div className="dashboard_leads_table_div">
            <CreatedUserTable
              setcurrdata={setcurrdata}
              tblHead={{
                firstHead: "Sr. No",
                secondHead: "Name",
                thirdHead: "Email",
                seventhHead: "Role",
                fourthHead: "Contact",
                fifthHead: "Show Dashboard",
                sixthHead: "Permissions",
                eighthHead: "Action",
              }}
              data={getAllUsers}
              getAllUser={getAllUser}
              redirectLink="/role&permission"
            />
          </div>
          {/* Pagination Div */}
          <div className="dashboard_leads_pagination_div">
            <nav aria-label="...">
              <ul className="pagination">
                {/* Previous page button */}
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handlePreviousPageClick}
                  >
                    <IoIosArrowBack />
                  </a>
                </li>

                {/* Render page numbers */}
                {Array.from({ length: pagesToShow }, (_, index) => {
                  const pageIndex = pageRangeStart + index;
                  return (
                    pageIndex < totalPages && (
                      <li
                        key={pageIndex}
                        className={`page-item ${
                          pageIndex === pageNo ? "active" : ""
                        } dashboard_leads_pagination_pageItem`}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => handlePageClick(pageIndex)}
                        >
                          {pageIndex + 1 < 10
                            ? `0${pageIndex + 1}`
                            : pageIndex + 1}
                        </a>
                      </li>
                    )
                  );
                })}

                {/* Next page button */}
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handleNextPageClick}
                  >
                    <IoIosArrowForward className="btn_IoIosArrowForward" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
      {/* new modal */}
      {createUser && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setcreateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <Signup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatedUser;
