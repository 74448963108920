import { Link, useNavigate } from "react-router-dom"
import { IoArrowBackCircleOutline } from "react-icons/io5"


  const Goback = ()=>{
   const navigate = useNavigate()


    return(
        <Link onClick={() => navigate(-1)} title="Go Back">
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link>
    )
  }
  export default Goback;