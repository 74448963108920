import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

// CSS
import "../styles/component_css/leadsLeftSection.css";
// React Icon
const FilterSidebar = ({ filterData,setFilterData }) => {
  const [filterField, setfilterField] = useState("");

  // Get By Default Data From Local Storage
  //  const savedFilter = JSON.parse(localStorage.getItem("filterData") || "{}");
  const savedFilter = JSON.parse(localStorage.getItem("filterData") || "{}");
  //const savedFilter = filterData;

  
  // Form Handle & Validations
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      [filterField]: "",
      // cityName: "",
      // companyName: "",
      // date: "",
      // leadOwnerName: "",
      dateRange: "",
      customStartDate: "",
      customEndDate: "",
      // verified: savedFilter.verified || false,
      // unverified: savedFilter.unverified || false,
      // rejected: savedFilter.rejected || false,
      verified: savedFilter.verified ,
      unverified: savedFilter.unverified,
      rejected: savedFilter.rejected ,
    },
    onSubmit: async (values, { resetForm }) => {
     
      setFilterData(values);
      //setValues()
      // resetForm();
    },
  });

  
  //  useEffect(()=>{
  //   if(values.dateRange==="custom"){
  //     setValues(prev=>({...prev,customStartDate:'',customEndDate:''}))
  //   }else{
  //     setValues(prev=>prev)
  //   }
  //  },[values.dateRange])
  // Vise Versa Filters
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // If "verified" checkbox is checked, uncheck "unverified" checkbox
    if (name === "verified" && checked) {
      setFieldValue("verified", true);
      setFieldValue("unverified", false);
      setFieldValue("rejected", false);
    } else if (name === "unverified" && checked) {
      setFieldValue("unverified", true);
      setFieldValue("verified", false);
      setFieldValue("rejected", false);
    } else if (name === "rejected" && checked) {
      setFieldValue("rejected", true);
      setFieldValue("verified", false);
      setFieldValue("unverified", false);
    }
  };

  return (
    <div className="filter-sidebar" style={{width:"100%"}}>
  
      <form onSubmit={handleSubmit} 
       style={{display:"flex",gap:"15px",alignItems:"start",flexDirection:"column"}}
      >
        <div className="filter-sidebar-header">
          <h3>Filters:</h3>
        </div>
        {/* System Defined Filter */}
        {/* -------------------------------------- */}
        <div className="filter-container_radio">
            <div className="form-check">
              <input
                name="verified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="touchedFilter"
                value={values.verified}
                // onChange={handleChange}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                //checked={true ? values.verified : "verified" || ""}
                checked={values.verified}
              />
              <label className="form-check-label" htmlFor="touchedFilter">
                Verified
              </label>
            </div>
            <div className="form-check">
              <input
                name="unverified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="untouchedFilter"
                value={values.unverified}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                //checked={true ? values.unverified : "unverified" || ""}
                checked={values.unverified}
              />
              <label className="form-check-label" htmlFor="untouchedFilter">
                Unverified
              </label>
            </div>
            <div className="form-check">
              <input
                name="rejected"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="rejectedFilter"
                value={values.rejected}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={values.rejected}
              />
              <label className="form-check-label" htmlFor="rejectedFilter">
                Rejected
              </label>
            </div>
          </div>
        {/* <details className="filter-container_detail">
          <summary>Filter By</summary>
          <div className="filter-container_radio">
            <div className="form-check">
              <input
                name="verified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="touchedFilter"
                value={values.verified}
                // onChange={handleChange}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={true ? values.verified : "verified" || ""}
              />
              <label className="form-check-label" htmlFor="touchedFilter">
                Verified
              </label>
            </div>
            <div className="form-check">
              <input
                name="unverified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="untouchedFilter"
                value={values.unverified}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={true ? values.unverified : "unverified" || ""}
              />
              <label className="form-check-label" htmlFor="untouchedFilter">
                Unverified
              </label>
            </div>
            <div className="form-check">
              <input
                name="rejected"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="rejectedFilter"
                value={values.rejected}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={values.rejected}
              />
              <label className="form-check-label" htmlFor="rejectedFilter">
                Rejected
              </label>
            </div>
          </div>
        </details> */}
        {/* ----------------------------------- */}
        {/* <div className="filter-container">
          <h4>System Defined Filter</h4>
          <div className="form-check">
            <input
              name="verified"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="touchedFilter"
              value={values.verified}
              // onChange={handleChange}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={true ? values.verified : "verified" || ""}
            />
            <label className="form-check-label" htmlFor="touchedFilter">
              Verified
            </label>
          </div>
          <div className="form-check">
            <input
              name="unverified"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="untouchedFilter"
              value={values.unverified}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={true ? values.unverified : "unverified" || ""}
            />
            <label className="form-check-label" htmlFor="untouchedFilter">
              Unverified
            </label>
          </div>
          <div className="form-check">
            <input
              name="rejected"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="rejectedFilter"
              value={values.rejected}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={values.rejected}
            />
            <label className="form-check-label" htmlFor="rejectedFilter">
              Rejected
            </label>
          </div>
        </div> */}
        {/* Filter By Fields */}
        <div className="filter-container" style={{display:'flex',flexDirection:"column",alignItems:'flex-start',rowGap:"15px",width:"100%"}}>
          {/* ---------------------- */}
          <select
            name="filterfrom"
            onChange={({ target: { value } }) => {
              setfilterField(value);
              setValues((prev) => {
                const { verified, unverified, rejected } = prev;
                return { verified, unverified, rejected, [value]: "" };
              });
            }}
            
          >
            <option hidden selected>
              Filter by
            </option>
            <option value="cityName">City Name</option>
            <option value="companyName">Company Name</option>
            {/* <option value="date">Date</option> */}
            <option value="leadOwnerName">LeadOwner Name</option>
          </select>
          {/* ---------------------- */}

          {/* ------------------------------- */}
          {filterField&&
          <div >
            <input
              id="filterfrom"
              type={values.hasOwnProperty("date") ? "date" : "text"}
              name={`${filterField}`}
              placeholder={
                filterField ? `Enter ${filterField}` : "Enter filter"
              }
              className="filters leads_filter_input_field"
              value={values[filterField]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
}
          {/* ------------------------------- */}
          {/* date range */}
          <div style={{width:"100%"}}>
            <select className="customrangefilter"
              onChange={handleChange}
              name="dateRange"
            
            >
              <option hidden selected>
                 By Date
              </option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="weekly">Last Week</option>
              <option value="monthly">Last Month</option>
              <option value="yearly">Last Year</option>
              <option value="custom">Custom</option>
            </select>

            {values?.dateRange === "custom" && (
              <div>
                <div>
                  <label htmlFor="customStartDate">From:</label>
                  <input
                    type="date"
                    name="customStartDate"
                    id="customStartDate"
                    value={values.customStartDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <label htmlFor="customEndDate">Till:</label>
                  <input
                    type="date"
                    name="customEndDate"
                    id="customEndDate"
                    value={values.customEndDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            )}
          </div>

          {/* **************************************************** */}
          {/* <div>
            <label htmlFor="cityName"></label>
            <input
              type="text"
              name="cityName"
              placeholder="Enter City"
              className="filters leads_filter_input_field"
              value={values.cityName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="companyName"></label>
            <input
              type="text"
              name="companyName"
              placeholder="Company name"
              className="filters leads_filter_input_field"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="date"></label>
            <input
              type="date"
              name="date"
              placeholder="Select date"
              className="filters leads_filter_input_field"
              value={values.date}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="leadOwner"></label>
            <input
              type="text"
              name="leadOwnerName"
              placeholder="Enter Lead Owner"
              className="filters leads_filter_input_field"
              value={values.leadOwnerName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div> */}
          
        </div>
        {/* Btns */}
        <div>
          <button style={{height:'33px',outline:"none",border:"1px solid rgba(161, 160, 160, 0.438)",borderRadius:"5px"}}
           //className="btn btn-primary filterBtn "
            type="submit">
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterSidebar;
