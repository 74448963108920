import * as Yup from "yup";

// Signup Form Schema
export const signupFormSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  lastName: Yup.string()
    .required("last name is required")
    .matches(/^[a-zA-Z]+(\s[A-Za-z]+)?$/, "only alphabets can be entered.")
    .max(50, "Lastname must be 50 characters or less")
    .trim(),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .matches(
      /^[a-z0-9._+-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  userName: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z0-9]+/, "username can contain alphabets and digits.")
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(/^[a-zA-Z0-9]{6,15}/, "you can enter 6 to 15 alphabets or digits ")
    .trim(),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "enter correct number")
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

// Update Profile Form Schema
export const updateProfileFormSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  lastName: Yup.string()
    .required("last name is required")
    .matches(/^[a-zA-Z]+(\s[A-Za-z]+)?$/, "only alphabets can be entered.")
    .max(50, "Lastname must be 50 characters or less")
    .trim(),
  // email: Yup.string().email("Invalid email").required("Email is required"),
  userName: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z]+[a-zA-Z0-9]+/, "username can contain alphabets and digits.")
    .max(15,'Enter maximum 15 characters.')
    .min(3,'Enter minimum 3 characters.')
    .trim(),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
  phone: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Number can be of 10 digits.")
    .trim(),
});

// Login Form Schema
export const loginFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
});

// Forgot Password Form Schema
export const forgotPasswordFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
});

// Reset Password Form Schema
export const resetPasswordFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

// OTP Verification
export const otpVerificationSchema = Yup.object().shape({
  digit1: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 1 must be a single digit"),
  digit2: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 2 must be a single digit"),
  digit3: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 3 must be a single digit"),
  digit4: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 4 must be a single digit"),
});

//MPIN validation
export const pinValidation = Yup.object().shape({
  dig1: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig2: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig3: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig4: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
});

// Create Lead Schema Vallidation
export const registerSchema = Yup.object({
  leadOwner: Yup.string()
    .required("Lead Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  lastName: Yup.string()
    .required("Last Name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Number can be of 10 digits.")
    .trim(),
  secondaryMobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Number can be of 10 digits.")
    .trim(),
  leadSource: Yup.string().required("Lead Source is required"),
  leadStatus: Yup.string().required("Lead Status is required"),
  leadService: Yup.string().required("Lead Service is required"),
  annualRevenue: Yup.number()
    .positive("Revenue must be 0 or more.")
    .typeError("Revenue must be a number")
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  companyName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(100, "maximum 50 character can be entered")
    .trim(),
  companyEmail: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      "please enter valid email"
    )
    .trim(),
  companyContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  secondaryContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  city: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  district: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  state: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  country: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
});

// Update Lead Schema validation
export const updateRegisterSchema = Yup.object({
  leadOwner: Yup.string()
    .required("Lead Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less")
    .min(5, "name should be minimum 5 character."),
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less"),
  lastName: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered without using space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  emailAlt: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "enter valid mobile no.")
    .trim(),
  secondaryMobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "enter valid mobile no.")
    .trim(),
  leadSource: Yup.string(),
  leadStatus: Yup.string(),
  annualRevenue: Yup.number()

    .typeError("Revenue must be a number")
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  queryMessage: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "only alphabet and space is allowed.")
    .trim(),
  companyName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(100, "Name must be 100 characters or less")
    .trim(),
  companyEmail: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  companyContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  secondaryContact: Yup.string()
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  city: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "City must be 50 characters or less")
    .trim(),
  district: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "District must be 50 characters or less")
    .trim(),
  state: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "State must be 50 characters or less")
    .trim(),
  country: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "State must be 50 characters or less")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .trim(),
});
// create company validation
export const CreateCompanySchema = Yup.object({
  firstName:Yup.string()
  .required("first name is required.")
  .matches(/^[A-Za-z]+(\s[a-zA-Z]+)?(\s[A-Za-z]+)?$/,'Please Enter Valid Name')
  .max(30,'Enter maximum 30 character')
  .min(1,'Please Enter First Name'),
  lastName:Yup.string()
  .required('last name is required')
  .matches(/^[A-Za-z]+(\s[a-zA-Z]+)?$/,'Please Enter Valid Name')
  .max(30,'Enter maximum 30 character')
  .min(1,'Please Enter First Name'),
  userName:Yup.string()
  .required("username is required.")
  .matches(/^[a-zA-Z0-9]{6,15}$/,'create username with alphabets or digits.')
  .label('Enter 6 to 15 characters for username.')
  .max(15,'Enter maximum 30 character')
  .min(6,'Please Enter First Name'),
  password:Yup.string()
  .required("Password is required")
  .matches(
    /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
    "password should have 6 to 15 alphabets or digits"
  )
  .trim(),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Password is required")
  .trim(),
  email: Yup.string()
  .required("Email is required")
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email id."
  )
  .trim(),
  mobile:Yup.string()
  .required("Mobile Number is required")
  .matches(/^[0-9]{10}$/, "Number can be of 10 digits.")
  .trim(),
  companyName: Yup.string()
  .required('company name is required.')
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)?$/,
    "only alphabets can be entered with single space."
  )
  .min(1, "atleast one character is required")
  .max(100, "maximum 50 character can be entered")
  .trim(),
  companyEmail:Yup.string()
  .required("Email is required")
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email"
  )
  .trim(),
  companyContact: Yup.string()
  .required("Company contact is required")
  .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
  .trim(),
  companyAddress:Yup.string()
  .required("Address is required")
  .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct address")
  .trim(),
  numberOfOffice:Yup.string()
  .matches(/[0-9]{1,4}/,'please enter valid number.')
  .label(' enter maximum 10 digits.'),
  numberOfDepartment:Yup.string()
  .matches(/[0-9]{1,4}/,'please enter valid number.')
  .label(' enter maximum 10 digits.'),
  numberOfDesignation:Yup.string()
  .matches(/[0-9]{1,4}/,'please enter valid number.')
  .label(' enter maximum 10 digits.'),
});
// Contact Schema Validation
export const ContactFormSchema = Yup.object({
  companyName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(100, "Name must be 100 characters or less")
    .trim(),
  companyEmail: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  companyContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  address: Yup.string()
    .required("Company address is required")
    .max(250, "Address can be upto 250 characters ")
    .matches(/^[a-zA-z0-9]+[A-Za-z0-9._%+\-\s]{2,}$/, "Enter correct address")
    .trim(),
  leadId: Yup.string()
    .required("lead id is required.")
    .matches(/^[0-9]+$/, "lead id should contain digits.")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
});

// Account Schema Validation
export const accountFormSchema = Yup.object({
  accountOwner: Yup.string()
    .required("Lead Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less")
    .min(5, "name should be minimum 5 character."),
  accountName: Yup.string()
    .trim()
    .required("Account name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "Name must be 50 characters or less"),
  accountSite: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .required("Account site is required")
    .max(50, "site should not have more than 50 character."),
  parentAccount: Yup.string()
    .matches(/^[0-9]+$/, "account no is a number.")
    .required("Parent account is required")
    .trim(),
  accountNumber: Yup.string()
    .matches(/^[0-9]+$/)
    .required("Account number is required")
    .trim(),
  aadharNumber: Yup.string()
    .matches(/^[0-9]{12}$/, "aadhaar number must be number.")
    .required("Aadhar number is required")
    .trim(),
  panCardNumber: Yup.string()
    .matches(
      /^[A-Za-z0-9]{10}$/,
      "only alphabets and no. can be entered without using space."
    )
    .required("PAN Card number is required")
    .trim(),
  accountType: Yup.string().required("Account type is required"),
  industry: Yup.string().required("Industry is required"),
  annualRevenue: Yup.number()
    .positive()
    .typeError("Revenue must be a number")
    .required("Annual revenue is required")
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  address: Yup.string()
    .required("Address is required")
    .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct address")
    .trim(),
  leadId: Yup.string()
    .matches(/^[0-9]{0,10}$/, "lead id must be number.")
    .required("Required Lead Id ")
    .trim(),
  billingAddress: Yup.string()
    .required("billingaddress is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct billingaddress"
    )
    .trim(),
  billingCity: Yup.string()
    .required("billingCity is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim(),
  billingState: Yup.string()
    .required("billingState is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct billingState"
    )
    .trim(),
  billingCode: Yup.string()
    .matches(/^[0-9]+$/, "billingCode is a number.")
    .required("Billing code is required")
    .trim(),
  shippingStreet: Yup.string()
    .required("shippingStreet is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingStreet"
    )
    .trim(),
  shippingCity: Yup.string()
    .required("shippingCity is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingCity"
    )
    .trim(),
  shippingState: Yup.string()
    .required("shippingState is required")
    .matches(
      /^[A-Za-z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingState"
    )
    .trim(),
  shippingCode: Yup.string()
    .matches(/^[0-9]+$/, "shippingCode is a number.")
    .required("Shipping code is required")
    .trim(),
  shippingAddress: Yup.string()
    .required("shippingAddress is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingAddress"
    )
    .trim(),
  dateOfIssue: Yup.date().required("Date of Issue is required"),
  dateOfBilling: Yup.date().required("Date of date of billing is required"),
  dateOfShipment: Yup.date().required("Date of date of shipment is required"),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .matches(
      /^[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
});

// Task Schema Validation
export const TaskFormSchema = Yup.object({
  taskOwner: Yup.string()
    .required("taskOwner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less")
    .min(5, "name should be minimum 5 character."),
  taskSubject: Yup.string()
    .required("Task Subject is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z\s]{1,50}$/,
      "only alphabets can be entered without using space."
    )
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
  priority: Yup.string().required("Task Priority is required"),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,10}$/, "leadid must be a number."),
  status: Yup.string().required("Status is required"),
  dueDate: Yup.date()
    .required("Due Date is required")
    .min(10, "Number must be at least 10 digits"),
  contact: Yup.number().required("Contact is required and must be a number"),
  accountType: Yup.string().required("Account Type is required"),
});

// Meeting Form Schema Validation
export const MeetingFormSchema = Yup.object({
  host: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .required("Host is required")
    .trim(),
  title: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .required("Title is required")
    .trim(),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct Address")
    .required("Address is required")
    .max(150, "Owner must be 50 characters or less")
    .trim(),
  date: Yup.date()
    .required("Date is required")
    .min(new Date(), "Date cannot be in the past"),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,10}$/, "lead id is a number upto 10 digits.")
    .trim(),
});

// Schedule Call Schema Validation
export const ScheduleCallSchema = Yup.object({
  callTo: Yup.string()
    .required("Call To is required")
    .max(50, "Callto must be 50 characters or less"),
  relatedTo: Yup.string().required("Related To is required"),
  callType: Yup.string().required("Call Type is required"),
  callStatus: Yup.string().required("Outgoing Call Status is required"),
  callOwner: Yup.string()
    .required("Call Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "please enter valid name")
    .max(50, "Call Owner must be 50 characters or less")
    .trim(),
  subject: Yup.string()
    .required("Subject is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  reminder: Yup.string().required("Reminder is required"),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,10}$/, "lead id is a numner."),

  callPurpose: Yup.string().required("Call Purpose is required"),
  callAgenda: Yup.string().required("Call Agenda is required"),
  callStartTime: Yup.date().required("Call Start Time is required"),
});

// Log Call Schema Validation
export const LogCallSchema = Yup.object({
  callTo: Yup.string()
    .required("Call To is required")
    .max(50, "Callto must be 50 characters or less"),
  relatedTo: Yup.string().required("Related To is required"),
  callType: Yup.string().required("Call Type is required"),
  callStatus: Yup.string()
    .matches(/^[a-zA-Z]+$/, "only alphabets can be entered.")
    .required("Outgoing Call Status is required"),
  callDuration: Yup.string()
    .required("Call Owner is required")
    .max(50, "Duration time 50 characters or less"),
  subject: Yup.string()
    .required("Subject is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "Subject must be 50 characters or less")
    .trim(),
  callResult: Yup.string().required("Reminder is required"),
  callPurpose: Yup.string().required("Call Purpose is required"),
  callAgenda: Yup.string().required("Call Agenda is required"),
  callStartTime: Yup.date().required("Call Start Time is required"),
});

// DealFormSchema
export const DealFormSchema = Yup.object({
  dealOwner: Yup.string()
    .required("dealOwner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  dealName: Yup.string()
    .required("Deal Name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  amount: Yup.number()
    .required("Amount is required")
    .typeError("Amount must be a number."),
  closingDate: Yup.date().required("Closing date is required"),
  accountName: Yup.string()
    .required("Account Name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  stage: Yup.string().required("Stage is required"),
  type: Yup.string().required("Type is required"),
  nextStep: Yup.string()
    .required("Next Step is required")
    .max(50, "Only 50 characters are allowed."),
  expectedRevenue: Yup.number()
    .required("Expected Revenue is required")
    .typeError("Revenue must be a number")
    .positive()
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  leadSource: Yup.string().required("Lead Source is required"),
  campaignSource: Yup.string()
    .matches(
      /^[a-zA-Z0-9\-]{1,50}$/,
      "only alphabets an digits can be entered."
    )
    .required("Campaign Source is required"),
  contactName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .required("Contact Name is required"),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,10}$/, "lead id is a numner."),
});
